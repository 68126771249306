import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { api } from '@/api';

Vue.use(VueI18n);

export const initLocalization = (locale?: string | null): VueI18n => {
    const params = new URLSearchParams(window.location.search);

    let lang = locale || (params.get('lang') ?? null);

    if (!lang && window.location.pathname.startsWith('/fr')) {
        lang = 'fr';
    }

    if (!lang) {
        lang = 'de';
    }

    api.setLocale(lang);

    const i18n = new VueI18n({
        locale: lang
    });

    api.i18n().then(res => res.data).then(messages => {
        i18n.setLocaleMessage(api.getLocale(), messages);
    });

    return i18n;
};

