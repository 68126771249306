





// @ts-ignore
import { createElement } from 'typed-html';

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import MarkerClusterer from '@googlemaps/markerclustererplus';

import { Loader } from '@googlemaps/js-api-loader';

import { Area, Location } from '@/types';
import { BlackAndWhiteMap } from '@/maps';
import { config } from '@/config';

@Component({
    props: {
        locations: {
            type: Array,
            required: true
        },
    }
})
export default class Map extends Vue {
    @Prop({ required: true, default: () => [] })
    public areas?: Area[];

    public map: google.maps.Map | null = null;
    public clusterer: MarkerClusterer | null = null;
    public infoWindow?: google.maps.InfoWindow;
    public polygon?: google.maps.Polygon;

    public mapOptions: google.maps.MapOptions = {
        center: { lat: 47, lng: 8 },
        zoom: 9,
        streetViewControl: false,
        heading: 2,
        styles: BlackAndWhiteMap,
        mapTypeControl: false
    };

    public markers: google.maps.Marker[] = [];

    public mounted() {
        const loader = new Loader({
            apiKey: 'AIzaSyAa9sDK3wSs738wr4-tcBzWa_NiQGLc678'
        });

        loader.load().then(() => {
            this.onScriptLoaded();
        });
    }

    public onScriptLoaded() {
        this.map = new google.maps.Map(this.$refs.map as HTMLElement, this.mapOptions);
        this.infoWindow = new google.maps.InfoWindow();

        this.polygon = new google.maps.Polygon({
            fillColor: '#004B95',
            fillOpacity: 0.3,
            strokeColor: '#004B95',
            map: this.map
        });

        this.clusterer = new MarkerClusterer(this.map, [], {
            maxZoom: 20,
            styles: [
                {
                    url: config.host.concat('/assets/icons/fial-map-cluster.png'),
                    width: 50,
                    height: 50,
                    textColor: '#fff',
                    textLineHeight: 50
                }
            ]
        });

        this.updateCluster();
    }

    @Watch('locations', { immediate: true })
    public onLocationsChanged() {
        if (!this.map) return;

        this.markers = [];

        this.$props.locations?.forEach((location: Location) => {
            if (!location.position) {
                return;
            }

            const marker = new google.maps.Marker({
                position: location.position,
                icon: config.host.concat('/assets/icons/fial-map-pin.png')
            });

            marker.addListener('click', () => {
                if (this.infoWindow && this.map) {
                    this.infoWindow.close();
                    this.infoWindow.setContent(this.getInfoWindowContent(location));
                    this.infoWindow.open(this.map, marker);
                }
            });

            this.markers.push(marker);
        });
    }

    @Watch('areas', { immediate: true })
    public onAreaChanged() {
        if (!this.map || !this.areas || this.areas.length === 0) {
            return;
        }

        this.polygon?.setPaths(this.areas);

        const bounds = new google.maps.LatLngBounds();
        this.areas.forEach(area => area.forEach(point => bounds.extend(point)));

        setTimeout(() => {
            this.map?.setCenter(bounds.getCenter());
            this.map?.fitBounds(bounds);
        }, 1);
    }

    @Watch('markers', { immediate: true })
    public updateCluster() {
        if (!this.clusterer || !this.markers) {
            return;
        }

        this.clusterer.clearMarkers();
        this.clusterer.addMarkers(this.markers);
    }

    public getInfoWindowContent(location: Location) {
        return (
            <div class={(this as any).$style.info}>
                <strong>{location.title}</strong>
                <span>{location.street}</span>
                <span>{location.zip} {location.city}</span>
                <br/>
                {location.branches.map(branch => (
                    <span>{branch.title}</span>
                ))}
                {location.website && (
                    <div>
                        <br/>
                        <a
                            target='_blank'
                            href={location.website.startsWith('http') ? location.website : `//${location.website}`}
                        >{this.$t('labels.models.location.website')}</a>
                    </div>
                )}
            </div>
        );
    }
}
