import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import App from './App.vue';
import { initLocalization } from './localization';

const node = document.querySelector('[data-fial-map]');
const locale = node?.getAttribute('data-locale');
const i18n = initLocalization(locale);

Sentry.init({
    Vue,
    dsn: 'https://9e7ac770a2794ba1b42042fc5dcd6a5b@sentry.services.wly.ch/4',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
});

if (node) {
    new App({
        i18n,
        propsData: {
            filterCanton: node.getAttribute('data-filter-canton'),
            filterBranch: node.getAttribute('data-filter-branch')
        }
    }).$mount(node);
}
