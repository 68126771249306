import axios from 'axios';
import { Area, Branch, Canton, Location } from '@/types';
import { LocaleMessageObject } from 'vue-i18n';
import { config } from '@/config';

const http = axios.create({
    baseURL: config.host.concat('/api')
});

export const api = {
    getLocale: (): string => http.defaults.params.lang ?? 'de',
    setLocale: (lang: string) => http.defaults.params = { lang },
    i18n: () => http.get<LocaleMessageObject>('/i18n'),
    locations: {
        all: () => http.get<Location[]>('/locations')
    },
    branches: {
        all: () => http.get<Branch[]>('/branches')
    },
    cantons: {
        all: () => http.get<Canton[]>('/cantons')
    },
    areas: {
        get: (canton: string): Promise<{ data: Area[] }> => {
            const cacheKey = 'fial.map.areas.v2.' + canton;
            const cache = window.localStorage.getItem(cacheKey);

            if (cache) {
                return Promise.resolve({
                    data: JSON.parse(cache)
                });
            }

            return http.get<Area[]>(`/areas/${canton}`).then(res => {
                window.localStorage.setItem(cacheKey, JSON.stringify(res.data));

                return res;
            });
        }
    }
};
