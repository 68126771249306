


































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Map from '@/components/Map.vue';
import { api } from '@/api';
import { Area, Branch, Canton, Location } from '@/types';
import Select from '@/components/Select.vue';

type FilterValue = string | null | undefined;

@Component({
    components: {
        'x-select': Select,
        'x-map': Map
    },
    props: {
        filterCanton: {
            type: String,
            required: false,
            default: () => null
        },
        filterBranch: {
            type: String,
            required: false,
            default: () => null
        }
    }
})
export default class App extends Vue {
    public ready: boolean = false;
    public locations: Location[] = [];
    public branches: Branch[] = [];
    public cantons: Canton[] = [];
    public areas: Area[] = [];

    public filter: { [key: string]: FilterValue } = {
        canton: undefined,
        branch: undefined,
        search: undefined
    };

    public created() {
        this.setAttributesFromUrl();
        this.fetchResources();
    }

    public get filteredBranches() {
        return this.branches
            .filter(branch => this.getLocations(this.filter.canton, branch.slug).length > 0)
            .map(c => ({ value: c.slug, label: c.title }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    public get filteredCantons() {
        const cantons = this.cantons
            .filter(canton => this.getLocations(canton.slug, this.filter.branch).length > 0)
            .map(c => ({ value: c.slug, label: c.title }))
            .sort((a, b) => a.label.localeCompare(b.label));

        // Move Liechtenstein to the end of the list
        const fl = cantons.splice(cantons.findIndex(o => o.value === 'fl'), 1);

        return [
            ...cantons,
            ...fl
        ];
    }

    public get filteredLocations(): Location[] {
        return this.getLocations(this.filter.canton, this.filter.branch, this.filter.search);
    }

    public get i18nLoaded() {
        return this.$i18n.availableLocales.length > 0;
    }

    public setAttributesFromUrl() {
        const params = new URLSearchParams(window.location.search);

        this.filter.canton = params.get('filter-canton') || this.$props.filterCanton;
        this.filter.branch = params.get('filter-branch') || this.$props.filterBranch;
    }

    /**
     * Fetches all resources.
     */
    public fetchResources() {
        return Promise.all([
            api.locations.all().then(res => res.data).then(locations => this.locations = locations),
            api.branches.all().then(res => res.data).then(branches => this.branches = branches),
            api.cantons.all().then(res => res.data).then(cantons => this.cantons = cantons)
        ]).then(() => {
            this.ready = true;
            this.fetchArea();
        });
    }

    public getLocations(canton: FilterValue, branch: FilterValue, search?: FilterValue): Location[] {
        return this.locations
            .filter(l => canton ? l.canton?.slug === canton : true)
            .filter(l => l.branches.filter(b => branch ? b.slug === branch : true).length > 0)
            .filter(l => {
                if (!search) {
                    return true;
                }

                const target = [
                    l.title,
                    l.street,
                    l.zip,
                    l.city,
                    l.website,
                    l.canton?.title,
                    l.branches.map(b => Object.values(b)).join()
                ].join().toLowerCase();

                return target
                    .toLowerCase().replace(' ', '')
                    .includes(search.toLowerCase().replace(' ', ''));
            })
            .sort((a, b) => {
                return a.title.localeCompare(b.title);
            });
    }

    @Watch('filter.canton', { deep: true, immediate: true })
    public fetchArea() {
        this.areas = [];

        const canton = this.filter.canton;

        if (canton === null) {
            api.areas.get('switzerland').then(res => this.areas = res.data);
            return;
        }

        if (typeof canton === 'string') {
            api.areas.get(canton).then(res => this.areas = res.data);
            return;
        }
    }

    @Watch('filter', { deep: true })
    public onFilterChanged() {
        const params = new URLSearchParams(window.location.search);

        if (this.filter.canton) {
            params.set('filter-canton', this.filter.canton);
        } else {
            params.delete('filter-canton');
        }

        if (this.filter.branch) {
            params.set('filter-branch', this.filter.branch);
        } else {
            params.delete('filter-branch');
        }

        const url = window.location.href.split('?')[0] + '?' + params.toString();

        window.history.pushState({}, window.document.title, url);
    }

    public scrollToMap() {
        const yOffset = -150;
        const y = (this.$refs.map as any).$el.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
    }
}
